.profile__title {
    margin: 0;
    max-width: 336px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21.78px;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 980px) {
    .profile__title {
        font-size: 14px;
        line-height: 16.94px;
        max-width: 282px;
        justify-self: center;
        margin-left: 24px;
    }
}