.popup__container-title-infotooltip {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    width: 358px;
    margin: auto;
}