.header__logout {
    color: #A9A9A9;
    text-align: right;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-decoration: none;
    margin: 26px 0 46px 24px;

}