.profile__name {
    margin: 0;
    max-width: 294px;
    min-height: 60px;
    width: 100%;
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 48px;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 980px) {
    .profile__name {
        font-size: 27px;
        line-height: 32.68px;
        max-width: 189px;
        max-height: 26px;
        justify-self: end;
        margin-left: 24px;

    }
}