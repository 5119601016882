.element__footer-text {
    font-weight: 900;
    font-size: 24px;
    line-height: 29.05px;
    margin: 0;
    padding: 0;
    max-width: 198px;
    max-height: 79px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}