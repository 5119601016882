.popup__form {
    border: 0;
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 37px;
}