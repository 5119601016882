.popup__close-img {
    width: 32px;
    height: 32px;
}

@media (max-width: 750px) {
    .popup__close-img {
        width: 20px;
        height: 20px;
    }
}