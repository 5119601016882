.header__logo {
    width: 142px;
    height: 33px;
    margin-right: auto;

}

.header__logo:hover {
    transition: opacity 0.2s;
    opacity: 60%;
}

@media (max-width: 768px) {
    .header__logo {
        width: 103.74px;
        height: 24.4px;
        margin-left: 27px;
        margin-bottom: 31.6px;
    }
}