.profile {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    max-width: 880px;
    min-height: 120px;
}

@media (max-width: 980px) {
    .profile {
        max-width: 703px;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .profile {
        max-width: 282px;
        flex-direction: column;
        justify-content: center;

    }
}