.popup__button-save {
    width: 358px;
    height: 50px;
    background-color: #000;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #fff;
    margin: 20px 0 20px;
    cursor: pointer;
    border-radius: 2px;
    border: 0;

}

.popup__button-save:hover {
    transition: opacity 0.2s;
    opacity: 80%;
}

@media (max-width: 750px) {
    .popup__button-save {
        top: -36px;
        right: 0;
        width: 238px;
        height: 46px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16.94px;
        margin-top: 16px;
        margin-right: 4px;
    }
}