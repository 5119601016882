.popup__container-title {
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29.05px;
    margin: 34px 87px 30px 35px;
    align-self: start;
}

@media (max-width: 750px) {
    .popup__container-title {
        font-size: 18px;
        line-height: 22px;
        margin: 20px 13px 72px 0;
    }
}