.profile__add-button {
    border-width: 2px;
    border-style: solid;
    border-color: white;
    background-color: black;
    width: 150px;
    height: 50px;
    align-self: center;
    margin-left: 244px;
    padding: 0;
    cursor: pointer;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.profile__add-button:hover{
    transition: opacity 0.2s;
    opacity: 60%;
}

@media (max-width: 980px) {
    .profile__add-button {
        margin: 25.5px 0 0 0;
        width: 282px;
    }
}