.popup__container {
    background-color: #fff;
    border-radius: 10px;
    width: 430px;
    height: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 34px 0 54px 0;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    align-self: center;
    z-index: 111;
}

@media (max-width: 750px) {
    .popup__container {
        width: 280px;
        height: 315px;
    }
}