.header__login {
    color: #FFF;
    text-align: right;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    height: 0;
    margin: 26px 0px 46px auto;
}