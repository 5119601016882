.registerForm__password {
    width: 358px;
    color: #CCC;
    border: 0;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 461px 0;
    background-color: transparent;
}