.footer__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 21.78px;
    color: #545454;
    margin: 0;
}

@media (max-width: 768px) {
    .footer__text {
        font-size: 14px;
        line-height: 16.94px;
    }
}