.popup-images__container-image {
    border-radius: 10px;
    max-height: 75vh;
    max-width: 75vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /*margin: 0 auto; */
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    /* align-self: center; */
    /* top: 0;
    right: 0;
    left: 0;
    bottom: 0; */
    background-color: transparent;
    transition: opacity 0.3s ease-in-out;
}