.element__trash-button {
    cursor: pointer;
    background: transparent;
    border: 0;
    position: absolute;
    margin: 20px 20px 243px 244px;
    padding: 0;
}

.element__trash-button:hover {
    opacity: 0.6;
}

@media (max-width: 1250px) {
    .element__trash-button {
        margin-left: 160px;
    }
}

@media (max-width: 750px) {
    .element__trash-button {
        margin-left: 244px;
    }
}