.loginForm__button {
    width: 358px;
    height: 50px;
    border-radius: 2px;
    background: #FFF;
    margin: 216px 461px 0;

    color: #000;
    text-align: center;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;

}